@import '../../../scss/variables.scss';

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.modal-container {
    // border: 1px solid var(--primary-color);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
    border-radius: 5px;

    text-align: center;
    padding: 20px;
    width: 100%;
    margin: 20px;

    .subtitle {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    &.no-margin {
        margin: 0;
    }
}

.modal-stats {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
}

.description >ol >li {
    margin-bottom: 8px;
}