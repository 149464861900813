@import '../../scss/variables.scss';
@import '../../scss/_mixins.scss';

.navbar {
    position: fixed;
    min-height: 100vh;
    min-width: 224px;
    background-color: $color-secondary;
    // z-index: 1;

    @media screen and (max-width: 1440px) {
        min-width: 180px;
    }

    .logo {
        padding: 48px 26px 52px 26px;
        margin: 16px;
        cursor: pointer;
    }

    .nav-footer {
        position: absolute;
        bottom: 48px;
        left: 0;
        right: 0;
        padding: 16px;

        .pp {
            margin-left: 10px;
            border-radius: 50%;
            padding: 1px 2px;
            color: white;
            background-color: var(--primary-color);
        }

        .separator {
            border-top: 1px solid #C9CACD;
            margin: 16px 0;
        }

        .version {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-secondary-text;
            padding-left: 10px;
        }

        .username {
            @include text(14px, $color-primary-text, 500, 20px);
            margin-left: 4px;
        }
    }

    .navbar-item {
        @include spacing((6px 16px), (8px 10px));
        cursor: pointer;
        background-color: inherit;
        color: inherit;
        border-radius: 4px;
    }

    .nav-group-item {
        padding: 8px 10px 8px 24px;
    }

    .nav-link {
        @include text(14px, $color-primary-text, 500, 20px);

        &:hover {
            color: white;
            background-color: var(--primary-color);
            transition: all 0.3s ease-in-out;
        }

        &.active {
            color: white;
            background-color: var(--primary-color);
        }
    }

}