// $color-green: #277B79;
// $color-red: #D9534F;
// $light-green : #DEF5F4;
// $color-black: #302D3C;
// $color-grey: #5A6474;
// $color-light-black : #2B2B2B;
// $color-light-green : #DCE4E2;
// $color-neutral-black : #324D45;
// $color-neon-green : #3CBDBA;
// $color-table-header: #5A6474;
// $color-table-content: #151515;
// $color-grey-input: #D0D0D0;
// $color-red-input-error: #C83532;
// $color-orange-input-warning: #EF8943;

$color-overflow-hover: #F5F8FF;
$color-light-green-nav : #F6F9F9;
$color-light-blue: #BDEAE9;
$color-zomp-border: #CFEDE7;
$color-display-grey: #F6F9F9;
$color-blue: #4FB2D6;
$color-border: #E5E5E5;
// $color-dark-grey: #2D3748;
$dark-grey: #2D3748;
$notification-grey: #222933;
$color-grey-login-input: #EDEDED;
$color-red-input-error: #C83532;
$color-black: #302D3C;
$color-light-grey: #F5F5F5;
$color-base-grey: #C9CACD;

// New
$color-primary: #11A387; // logo, text, btns, icons.
$color-grey-border: #E9ECEF;
$color-secondary: #F5F5F5; // sidebar
$color-table-bar: #E9E9EA; // table footer and header
$color-link: #227AE5; // cta
$color-primary-text: #4B5054;
$color-form-label: #5F6467;
$color-secondary-text: #93959B;
$color-alert-red: #CC2626;
$color-danger-red: #EB6262;
$color-success-green: #2DA24F; // font color for success btn
$color-light-green: #F8FFFA; //background for success btn
$color-disabled: #C9CACD;
$color-hyperlink-blue: #3984DA;
$color-light-blue-bg: #E1EDF9;
// width 
$max-width: 1168px;
$mobile-breakpoint: 575px;