@import '../../../scss/_variables.scss';
@import '../../../scss/_mixins.scss';

.claim-title {
    margin: 16px 0 24px 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: black;
}

.claims-form {
    margin-top: 56px;
}

.document-upload {
    border-bottom: 1px solid #C9CACD;
    padding-bottom: 24px;
    margin-bottom: 48px;
}

.document-status {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.verify-cta {
    font-size: 12px;
}

.custom-label {
    @include text(14px, $color-form-label, 400, 20px);
    height: auto;
    margin-bottom: 4px !important;
    padding: 0 !important;
}

.communication-divider {
    border-bottom: 1px solid #C9CACD;
    padding-bottom: 32px;
    margin-bottom: 24px;
}

.claims-dashboard-box {
    border: 1px solid #C9CACD;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;

    .ant-statistic-content {
        font-size: 20px;
    }

    .ant-statistic-title {
        font-size: 12px;
    }
}

.k-grid {
    width: 100% !important;
    max-width: 100% !important;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

.pdf-key {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-form-label;
    margin-bottom: 4px;
}

.pdf-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    color: $color-primary-text;
}

.pdf-analytics {
    .analytics-box {
        .analytics-title {
            font-size: 11px !important;
            font-weight: 400;
            color: $color-form-label;
            line-height: 150%;
            margin-bottom: 8px;
            letter-spacing: 1px;
            height: auto !important;
        }

        .primary-value {
            font-size: 14px !important;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 8px;
            height: auto !important;
        }
    }
}